import Accordion from '@/components/Accordion'
import { useMenuContext } from '@/util/MenuContext'

interface Props {
  categories: string[]
}

const MenuCategories = (props: Props) => {
  const { categories } = props
  const { menuData, setMenuData } = useMenuContext()

  const handleClick = (button: string) => {
    if (menuData.category === button) return
    setMenuData((prevData) => ({
      ...prevData,
      category: button,
    }))
  }

  return (
    <Accordion title={`Menu Categories`} isOpen={true}>
      <div className="border-t mb-3 border-[#595959]" />
      <button
        key="all"
        onClick={() => handleClick('all')}
        className={`rounded-full border-black dark:border-white border py-1 px-3 mx-[2px] my-1 cursor-pointer 
${menuData.category === 'all' ? 'bg-white text-black' : ''}
`}
      >
        All
      </button>
      {categories.map((category) => {
        return (
          <button
            key={`cat-${category}`}
            className={`rounded-full border-black dark:border-white border py-1 px-3 mx-[2px] my-1 cursor-pointer whitespace-nowrap 
${menuData.category === category ? 'bg-white text-black' : ''}
`}
            onClick={() => handleClick(category)}
          >
            {category}
          </button>
        )
      })}
    </Accordion>
  )
}

export default MenuCategories
