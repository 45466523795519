import { Fragment, useState, useEffect, useRef, RefObject } from 'react'
import { PriceRange } from '@/types/FoodMerchant'
import { useMenuContext } from '@/util/MenuContext'

interface Props {
  merchantPriceRange: PriceRange
  limitedMaxPrice: null | 50
}

const MenuSlider = (props: Props) => {
  const { merchantPriceRange, limitedMaxPrice } = props
  const min = merchantPriceRange.min
  const max = limitedMaxPrice ? limitedMaxPrice : merchantPriceRange.max
  const priceGap = (max - min) * 0.1

  const [minValue, setMinValue] = useState<number>(min)
  const [tempMinValue, setTempMinValue] = useState<number>(min)
  const [maxValue, setMaxValue] = useState<number>(max)
  const [tempMaxValue, setTempMaxValue] = useState<number>(max)
  const [minDragging, setMinDragging] = useState(false)
  const [maxDragging, setMaxDragging] = useState(false)
  const { menuData, setMenuData, resetCounter } = useMenuContext()

  const minSliderRef = useRef<HTMLInputElement>(null)
  const maxSliderRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (
      menuData.priceRange.min !== minValue ||
      menuData.priceRange.max !== maxValue
    ) {
      setMenuData((prevData) => ({
        ...prevData,
        priceRange: {
          min: minValue,
          max: maxValue,
        },
      }))
    }
  }, [maxValue, minValue, setMenuData])

  useEffect(() => {
    setMinValue(min)
    setTempMinValue(min)
    setMaxValue(max)
    setTempMaxValue(max)
  }, [resetCounter, max, min])

  const toInteger = (s: string) => {
    return parseInt(s, 10)
  }

  const calculateTooltipPosition = (
    sliderRef: RefObject<HTMLInputElement>,
    value: number
  ) => {
    if (!sliderRef.current) return 0
    const slider = sliderRef.current
    const percentage = (value - min) / (max - min)
    const thumbWidth = 24
    const tooltipWidth = 40
    let offset = percentage * (slider.clientWidth - thumbWidth)
    offset = offset + thumbWidth / 2 - tooltipWidth / 2
    return offset
  }

  const getPercentage = (val: number) => {
    return ((val - min) / (max - min)) * 100
  }

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = toInteger(e.target.value)
    if (val <= maxValue - priceGap) {
      setTempMinValue(val)
    }
  }

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = toInteger(e.target.value)
    if (val >= minValue + priceGap) {
      setTempMaxValue(val)
    }
  }

  const handleMinUserEvent = (
    e: React.MouseEvent<HTMLInputElement> | React.TouchEvent<HTMLInputElement>
  ) => {
    switch (e.type) {
      case 'mousedown':
      case 'touchstart':
        setMinDragging(true)
        break
      case 'mouseup':
      case 'touchend':
        setMinDragging(false)
        if (tempMinValue <= maxValue - priceGap) {
          setMinValue(tempMinValue)
        }
        break
      default:
        break
    }
  }

  const handleMaxUserEvent = (
    e: React.MouseEvent<HTMLInputElement> | React.TouchEvent<HTMLInputElement>
  ) => {
    switch (e.type) {
      case 'mousedown':
      case 'touchstart':
        setMaxDragging(true)
        break
      case 'mouseup':
      case 'touchend':
        setMaxDragging(false)
        if (tempMaxValue >= minValue + priceGap) {
          setMaxValue(tempMaxValue)
        }
        break
      default:
        break
    }
  }

  const getInitialMaxStyle = () => {
    if (maxSliderRef.current === null) {
      return { right: '0px' }
    }
    if (maxSliderRef.current.clientWidth === 0) {
      return { right: '0px' }
    }
    return {
      left: `calc(${calculateTooltipPosition(maxSliderRef, tempMaxValue)}px)`,
    }
  }

  const getMaxSliderVal = (val: number) => {
    if (limitedMaxPrice) {
      if (val === limitedMaxPrice) {
        return `>$${val}`
      }
    }
    return `$${val}`
  }

  return (
    <Fragment>
      <div className="w-full rounded-xl py-5 p-1 relative">
        <div>
          <div className="h-[5px] relative bg-[#595959] rounded-[5px]">
            <div
              className="h-full absolute rounded-[5px] bg-white"
              style={{
                left: `calc(${getPercentage(tempMinValue)}%)`,
                right: `calc(${100 - getPercentage(tempMaxValue)}%)`,
              }}
            ></div>
          </div>
        </div>
        <div className="rangeInput relative">
          <div
            className="tooltip"
            style={{
              left: `calc(${calculateTooltipPosition(minSliderRef, tempMinValue)}px)`,
            }}
          >
            {'$' + tempMinValue}
          </div>
          <div className="tooltip " style={getInitialMaxStyle()}>
            {getMaxSliderVal(tempMaxValue)}
          </div>

          <input
            id="minRange"
            ref={minSliderRef}
            className={`slider ${minDragging ? 'dragging' : ''}`}
            type="range"
            min={min}
            max={max}
            value={tempMinValue}
            step="1"
            onChange={handleMinChange}
            onMouseDown={handleMinUserEvent}
            onMouseUp={handleMinUserEvent}
            onMouseMove={handleMinUserEvent}
            onTouchStart={handleMinUserEvent}
            onTouchEnd={handleMinUserEvent}
            onTouchMove={handleMinUserEvent}
          />
          <input
            id="maxRange"
            ref={maxSliderRef}
            className={`slider ${maxDragging ? 'dragging' : ''}`}
            type="range"
            min={min}
            max={max}
            value={tempMaxValue}
            step="1"
            onChange={handleMaxChange}
            onMouseDown={handleMaxUserEvent}
            onMouseUp={handleMaxUserEvent}
            onMouseMove={handleMaxUserEvent}
            onTouchStart={handleMaxUserEvent}
            onTouchEnd={handleMaxUserEvent}
            onTouchMove={handleMaxUserEvent}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default MenuSlider
