import {
  currentDayOpenTime,
  isCurrentTimeWithinOperatingHours,
} from '@/util/MerchantStatus'
import { useEffect, useState } from 'react'
import { Hours } from '@/types/FoodMerchant'
import HoursOverlay from '@/components/FoodCard/HoursOverlay'

type Props = {
  hours: Hours
}

const HourBlock = ({ hours }: Props) => {
  const [openHours, setOpenHours] = useState<boolean>(false)
  const [merchantTime, setMerchantTime] = useState({
    openTime: '',
    closeTime: '',
  })

  const uppperCaseFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const getNextOpenTime = (): string => {
    const daysOfWeek = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ]
    const now = new Date()
    const currentDay = now
      .toLocaleString('en-US', {
        weekday: 'long',
      })
      .toLowerCase()

    if (hours[currentDay] !== 'Closed') {
      return hours[currentDay]
    }

    const dayIndex = daysOfWeek.indexOf(currentDay)

    for (let i = dayIndex; i < daysOfWeek.length; i++) {
      const day = daysOfWeek[i]
      if (hours[day] !== 'Closed') {
        return `${uppperCaseFirstLetter(day)}, ${hours[day]}`
      }
    }

    for (let i = 0; i < dayIndex; i++) {
      const day = daysOfWeek[i]
      if (hours[day] !== 'Closed') {
        return `${uppperCaseFirstLetter(day)} \u00B7 ${hours[day]}`
      }
    }

    return 'Permanently closed'
  }

  useEffect(() => {
    if (hours) {
      const day = currentDayOpenTime(hours)
      day
        ? setMerchantTime(day)
        : setMerchantTime({ openTime: 'Closed', closeTime: 'Closed' })
    }
  }, [hours])

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setOpenHours((prev) => !prev)
    e.preventDefault()
    e.stopPropagation()
  }

  const getOpenDisplay = () => {
    if (merchantTime?.openTime === 'Open 24 hours') {
      return (
        <div className={`cursor-pointer `}>
          <span className="text-success">Open &centerdot;&nbsp;</span>
          <span className={``}>Open 24 Hours</span>
        </div>
      )
    }
    if (isCurrentTimeWithinOperatingHours(hours)) {
      return (
        <div className={`cursor-pointer `}>
          <span className="text-success">Open</span>
          <span className={``}>
            &nbsp;&#183; {merchantTime?.openTime} - {merchantTime?.closeTime}
          </span>
        </div>
      )
    }
    return (
      <div className={`flex w-max cursor-pointer `}>
        <span className="text-error">Closed</span>
        <span>&nbsp;&#183; Opens&nbsp;</span>
        <span>{getNextOpenTime()}</span>
      </div>
    )
  }

  return (
    <>
      <HoursOverlay
        hours={hours}
        trigger={openHours}
        setTrigger={setOpenHours}
      />

      {Object.values(hours).every((day) => !day) ? (
        <div>Hours Unknown</div>
      ) : (
        <div onClick={handleClick} className="w-fit ">
          <div>{getOpenDisplay()}</div>
        </div>
      )}
    </>
  )
}

export default HourBlock
