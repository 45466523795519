import { createContext, useContext, useState, ReactNode } from 'react'
import { MenuState, MenuContextProps } from '@/types/FoodMerchant'

const MenuContext = createContext<MenuContextProps | undefined>(undefined)

export const useMenuContext = (): MenuContextProps => {
  const context = useContext(MenuContext)
  if (!context) {
    throw new Error('useMenuContext must be used with a MenuProvider')
  }
  return context
}

interface MenuProviderProps {
  initialState: MenuState
  children: ReactNode
}

export const MenuProvider = (props: MenuProviderProps) => {
  const { initialState, children } = props
  const [menuData, setMenuData] = useState<MenuState>(initialState)
  const [resetCounter, setResetCounter] = useState<number>(0)

  const resetMenuData = () => {
    setMenuData(initialState)
    setResetCounter((prev) => prev + 1)
  }

  return (
    <MenuContext.Provider
      value={{ menuData, setMenuData, resetMenuData, resetCounter }}
    >
      {children}
    </MenuContext.Provider>
  )
}
