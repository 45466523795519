import Modal from '@/components/Modal'
import { Image } from '@/types/FoodMerchant'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

interface ViewerProps {
  images: Image[]
  initialImage: number
  trigger: boolean
  setTrigger: Dispatch<SetStateAction<boolean>>
}

const PhotoViewer: React.FC<ViewerProps> = ({
  images,
  initialImage = 0,
  trigger,
  setTrigger,
}) => {
  const [imageCount, setImageCount] = useState<number>(initialImage)

  const handleLeftArrow = () => {
    if (imageCount === 0) {
      setImageCount(images.length - 1)
      return
    }
    setImageCount((imageCount) => imageCount - 1)
  }
  const handleRightArrow = () => {
    if (imageCount === images.length - 1) {
      setImageCount(0)
      return
    }
    setImageCount((imageCount) => imageCount + 1)
  }
  return (
    <>
      <Modal state={trigger} setTrigger={setTrigger} photoViewer={true}>
        <header className="w-full relative text-white pb-7 ">
          {images[imageCount].attribution && (
            <div className="absolute top-0 left-0">{`Photo By: ${images[imageCount].attribution}`}</div>
          )}
          <div className="absolute top-0 right-0">
            <XMarkIcon
              className="h-6 w-6 cursor-pointer"
              onClick={() => setTrigger(false)}
            />
          </div>
        </header>

        <div className="flex w-full justify-center md:h-[600px] h-[300px]">
          <img
            src={`${process.env.NEXT_PUBLIC_API_URL}/assets/images/${images[imageCount].id}/${images[imageCount].path_to_image}.jpg`}
            alt="Selected Image"
            className={`w-fit object-contain`}
          />
        </div>
        <div className="text-white flex justify-center w-full gap-x-2 mt-2">
          <button>
            <ChevronLeftIcon className="h-6 w-6" onClick={handleLeftArrow} />
          </button>
          <button>
            <ChevronRightIcon className="h-6 w-6" onClick={handleRightArrow} />
          </button>
        </div>
      </Modal>
    </>
  )
}

export default PhotoViewer
