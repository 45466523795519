import { Hours } from '@/types/FoodMerchant'

export function isCurrentTimeWithinOperatingHours(hours: Hours) {
  const now = new Date()
  const currentDay = now.toLocaleString('en-US', { weekday: 'long' })
  const currentTime = now.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })

  const currentHours = hours[currentDay.toLowerCase()]
  if (!currentHours) {
    return false // Day not found in the hours object
  }

  const [startTime, endTime] = currentHours.split(' - ')
  return isTimeWithinRange(currentTime, startTime, endTime)
}

export function currentDayOpenTime(hours: Hours) {
  const now = new Date()
  const currentDay = now.toLocaleString('en-US', { weekday: 'long' })

  const currentHours = hours[currentDay.toLowerCase()]
  if (!currentHours) {
    return false // Day not found in the hours object
  }
  const [openTime, closeTime] = currentHours.split(' - ')

  return { openTime, closeTime }
}

function isTimeWithinRange(time: string, startTime: string, endTime: string) {
  const currentTime = parseTime(time)
  const rangeStart = parseTime(startTime)
  const rangeEnd = parseTime(endTime)
  return currentTime >= rangeStart && currentTime <= rangeEnd
}

function parseTime(time: string) {
  if (time === undefined) {
    return new Date()
  }
  const [timeString, period] = time.split(' ')

  if (timeString === undefined) {
    return new Date()
  }

  const [hours, minutes] = timeString.split(':').map(Number)
  let hours24 = hours

  if (period === 'PM' && hours !== 12) {
    hours24 = hours + 12
  } else if (period === 'AM' && hours === 12) {
    hours24 = 0
  }

  return new Date(0, 0, 0, hours24, minutes, 0, 0)
}
