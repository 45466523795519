import { Dialog, Transition } from '@headlessui/react'
import { useState, Fragment, useEffect, ReactNode } from 'react'

type Props = {
  children: ReactNode
  state: boolean
  setTrigger: (trigger: boolean) => void
  photoViewer?: boolean
}

const defaultProps = {
  photoViewer: false,
}

const Modal = (props: Props) => {
  const { children, state, setTrigger, photoViewer } = {
    ...defaultProps,
    ...props,
  }
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    setOpen(state)
  }, [state])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[2500]"
        onClose={(val) => {
          setOpen(val)
          setTrigger(val)
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div
            id={'modal'}
            className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6
${photoViewer ? 'bg-[#333] w-full sm:max-w-lg' : 'bg-white sm:max-w-sm min-w-72'}
`}
              >
                <div>{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
