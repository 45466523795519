import { Hours } from '@/types/FoodMerchant'
import Modal from '@/components/Modal'

interface HourPopup {
  setTrigger: (trigger: boolean) => void
  trigger: boolean
  hours?: Hours
}

const HoursOverlay = (props: HourPopup) => {
  const { setTrigger, trigger, hours } = props

  return (
    <Modal state={trigger} setTrigger={setTrigger}>
      {hours &&
        (Object.values(hours).every((day) => !day) ? (
          <div className="text-center">
            <div className="text-lg font-bold text-black">
              No hours available
            </div>
            <div className="text-sm text-black">
              This merchant has not provided their hours of operation.
            </div>
          </div>
        ) : (
          <div className="grid grid-flow-row-dense grid-cols-2 text-black">
            <div>Monday:</div>
            <div>{hours?.monday}</div>
            <div>Tuesday: </div>
            <div>{hours?.tuesday}</div>
            <div>Wednesday: </div>
            <div>{hours?.wednesday}</div>
            <div>Thursday: </div>
            <div>{hours?.thursday}</div>
            <div>Friday: </div>
            <div>{hours?.friday}</div>
            <div>Saturday:</div>
            <div>{hours?.saturday}</div>
            <div>Sunday:</div>
            <div>{hours?.sunday}</div>
          </div>
        ))}
    </Modal>
  )
}

export default HoursOverlay
