import { useState, useEffect, ChangeEvent, FormEventHandler } from 'react'
import Modal from '@/components/Modal'
import { PhotoIcon } from '@heroicons/react/24/solid'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'

type Props = {
  setTrigger: (trigger: boolean) => void
  trigger: boolean
  itemId: number
  type: string
}

const UploadImage = (props: Props) => {
  const { setTrigger, trigger, itemId, type } = props
  const { loginWithRedirect, getAccessTokenSilently } = useAuth0()
  const [formData, setFormData] = useState({
    image: '',
  })
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const { isAuthenticated } = useAuth0()
  const [submission, setSubmission] = useState(false)

  const [error, setError] = useState(false)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target

    if (files) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: name === 'image' ? files[0] : value,
      }))

      if (name === 'image' && files.length > 0) {
        setSelectedFile(files[0])
      }
    }
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    const token = await getAccessTokenSilently()
    const apiUrl = process.env.NEXT_PUBLIC_API_URL

    const formDataPayload = new FormData()
    formDataPayload.append('item_id', itemId.toString())
    formDataPayload.append('image', formData.image)
    formDataPayload.append('type', type)

    const res = await axios
      .post(`${apiUrl}/image/${itemId}/upload`, formDataPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => {
        setError(true)
        console.log('err')
      })

    if (res) {
      console.log('image uploaded')
      setSubmission(true)
    }
  }

  useEffect(() => {
    setSubmission(false)
  }, [itemId])

  return (
    <Modal state={trigger} setTrigger={setTrigger}>
      {submission ? (
        <div className={`text-black text-center`}>
          Thank your for your contribution
        </div>
      ) : isAuthenticated ? (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="col-span-full">
            <label
              htmlFor="photo"
              className="flex text-sm font-medium leading-6 text-gray-900 justify-center"
            >
              contribute a photo
            </label>
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
              <div className="text-center">
                {selectedFile ? (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Image Preview"
                    style={{ maxWidth: '200px', marginTop: '10px' }}
                  />
                ) : (
                  <PhotoIcon
                    className="mx-auto h-12 w-12 text-gray-300"
                    aria-hidden="true"
                  />
                )}
                <div className="mt-4  text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="image"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none hover:text-indigo-500"
                  >
                    <span>Upload a file</span>
                    <input
                      id="image"
                      name="image"
                      type="file"
                      className="sr-only"
                      onChange={handleChange}
                    />
                  </label>
                </div>
                <p className="text-xs leading-5 text-gray-600">up to 10MB</p>
              </div>
            </div>
          </div>
          {error && (
            <div className={`text-black text-center text-xs mt-4`}>
              Upload limit reached
            </div>
          )}
          <div className="flex justify-center">
            <button
              type="submit"
              className="mt-4 rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
            >
              Upload
            </button>
          </div>
        </form>
      ) : (
        <div
          className={`text-link text-center`}
          onClick={() => loginWithRedirect()}
        >
          Signup / Login to submit an image
        </div>
      )}
    </Modal>
  )
}

export default UploadImage
