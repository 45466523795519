import Head from 'next/head'

interface HeadData {
  title: string
  description: string
  url: string
  image: string
  canonical: string
}

interface CustomHeadProps {
  data?: Partial<HeadData>
}

const defaultData: HeadData = {
  title: 'YumDodo',
  description:
    'YumDodo the most trustworthy, complete source of food information',
  url: `${process.env.NEXT_PUBLIC_URL}`,
  image: `${process.env.NEXT_PUBLIC_URL}/logo.svg`,
  canonical: `${process.env.NEXT_PUBLIC_URL}`,
}

const CustomHead = ({ data = {} }) => {
  const mergedData = { ...defaultData, ...data }

  return (
    <Head>
      <title>{mergedData.title}</title>
      <meta property="description" content={mergedData.description} />
      <meta itemProp="description" content={mergedData.description} />
      <meta name="description" content={mergedData.description} />

      <meta property="og:title" content={mergedData.title} />
      <meta property="og:site_name" content="YumDodo" />
      <meta property="og:url" content={mergedData.url} />
      <meta property="og:description" content={mergedData.description} />
      <meta property="og:image" content={mergedData.image} />
      <meta key="og:image:width" property="og:image:width" content="1200" />
      <meta key="og:image:height" property="og:image:height" content="630" />

      <link rel="canonical" href={mergedData.canonical} />

      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta content="yes" name="apple-touch-fullscreen" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="format-detection" content="telephone=no" />
      <link
        rel="icon"
        type="image/svg+xml"
        sizes="16x16 32x32 48x48 64x64"
        href="/assets/logo.svg"
      />
      <link rel="apple-touch-icon" href="/touch-icon-iphone.png" />

      {/*Twitter*/}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:domain"
        content={`${process.env.NEXT_PUBLIC_URL}`}
      />
      <meta property="twitter:url" content={mergedData.url} />
      <meta name="twitter:title" content={mergedData.title} />
      <meta name="twitter:description" content={mergedData.description} />
      <meta name="twitter:image" content={mergedData.image} />
    </Head>
  )
}

export default CustomHead
