import { Categories } from '@/types/FoodMerchant'
import { useState, Fragment, useEffect } from 'react'
import MenuSlider from '@/components/MenuSlider'
import { PriceRange } from '@/types/FoodMerchant'
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/solid'
import Accordion from '@/components/Accordion'
import MenuCategories from '@/components/MenuCategories'
import { useMenuContext } from '@/util/MenuContext'
import PillFiltersContainer from '@/components/PillFiltersContainer'
import { useFilterStore } from '@/hooks/useFilterStore'

type Props = {
  isSidebar: boolean
  categories: string[]
  items: Categories[]
  merchantPriceRange: PriceRange
}

const Menu = (props: Props) => {
  const { categories, items, merchantPriceRange, isSidebar } = props
  const [filterOpen, setFilterOpen] = useState(false)
  const [limitedMaxPrice, setLimitedMaxPrice] = useState<null | 50>(null)
  const [filteredItems, setFilteredItems] = useState<Categories[]>(items)
  const { menuData, resetMenuData } = useMenuContext()
  const [totalCount, setTotalCount] = useState(0)

  const [itemCount, setItemCount] = useState(0)

  const { filters: selectedFilters, setFilters: setSelectedFilters } =
    useFilterStore((state) => state)

  const filterItems = (objects: any[], condition: (item: any) => boolean) => {
    return objects.map((obj) => ({
      ...obj,
      items: obj.items.filter(condition),
    }))
  }

  useEffect(() => {
    const total = items.reduce((accumulator, currentObject) => {
      return accumulator + currentObject.items.length
    }, 0)

    setTotalCount(total)

    const filteredData = filterItems(items, (item) => {
      const restrictions = item.restrictions
        ? item.restrictions.map((x: { name: any }) => x.name)
        : []
      return selectedFilters.every((sf) => {
        return restrictions.includes(sf)
      })
    })
    if (merchantPriceRange.max > 50) {
      setLimitedMaxPrice(50)
    }
    if (
      selectedFilters.filter((f) => !['indie', 'franchise'].includes(f))
        .length > 0
    ) {
      setFilteredItems(filteredData)
    }
  }, [items])

  useEffect(() => {
    const selectedCategory = menuData.category
    const selectedPriceRange = menuData.priceRange
    const filteredCategories =
      selectedCategory === 'all'
        ? items
        : items.filter((f) => f.name === selectedCategory)

    const priceFilteredItems = filteredCategories.reduce<Categories[]>(
      (acc, category) => {
        const priceFilteredItems = category.items.filter((item) => {
          if (limitedMaxPrice && selectedPriceRange.max === limitedMaxPrice) {
            return item.cost >= selectedPriceRange.min
          } else {
            return (
              item.cost <= selectedPriceRange.max &&
              item.cost >= selectedPriceRange.min
            )
          }
        })

        if (priceFilteredItems.length > 0) {
          acc.push({
            ...category,
            items: priceFilteredItems,
          })
        }

        return acc
      },
      []
    )

    if (
      selectedFilters.filter((f) => !['indie', 'franchise'].includes(f))
        .length > 0
    ) {
      const filteredData = filterItems(priceFilteredItems, (item) => {
        const restrictions = item.restrictions
          ? item.restrictions.map((x: { name: any }) => x.name)
          : []

        return selectedFilters.every((sf) => {
          return restrictions.includes(sf)
        })
      })
      setFilteredItems(filteredData)
      setItemCount(
        filteredData.reduce((accumulator, currentObject) => {
          return accumulator + currentObject.items.length
        }, 0)
      )
    } else {
      setFilteredItems(priceFilteredItems)
      setItemCount(
        priceFilteredItems.reduce((accumulator, currentObject) => {
          return accumulator + currentObject.items.length
        }, 0)
      )
    }
  }, [items, menuData.category, menuData.priceRange, selectedFilters])

  const handleReset = () => {
    resetMenuData()
    setFilteredItems(items)
    setSelectedFilters([])
  }

  return (
    <div className={`block my-6`}>
      <div
        className={`flex flex-row justify-between py-4 md:border-b md:border-[#9797AA] mb-6`}
      >
        <p className={`text-2xl font-bold`}>Menu</p>

        <div
          className="flex gap-1 cursor-pointer"
          onClick={() => setFilterOpen(!filterOpen)}
        >
          <FunnelIcon
            className={`w-6 h-6 ${filterOpen ? 'hidden md:block' : 'block md:hidden'}`}
          />
          <p>Filter</p>
          <XMarkIcon
            className={`w-6 h-6 ${filterOpen ? 'md:hidden block' : 'md:block hidden'}`}
            onClick={() => setFilterOpen(!filterOpen)}
          />
        </div>
      </div>

      <div
        className={isSidebar ? `flex flex-col` : `flex flex-col md:flex-row`}
      >
        <div
          className={
            isSidebar
              ? `w-full border-[#595959]  ${filterOpen ? 'block md:hidden' : 'md:block hidden'}`
              : `w-full md:w-1/3 md:order-2 md:ml-4 md:border-l md:pl-4 border-[#595959] md:block 
            ${filterOpen ? 'block md:hidden' : 'md:block hidden'} flex flex-col`
          }
        >
          <div className={`mb-8`}>
            <p>
              Items: {itemCount} / {totalCount}
            </p>
          </div>
          <div>
            <MenuSlider
              merchantPriceRange={merchantPriceRange}
              limitedMaxPrice={limitedMaxPrice}
            />
          </div>
          <div>
            <MenuCategories categories={categories} />
          </div>
          <div>
            <Accordion title={`Restrictions`} isOpen={true}>
              <div className="border-t mb-3 border-[#595959]" />
              <PillFiltersContainer shouldSetSearch={false} />
            </Accordion>
          </div>

          <div className="flex flex-col gap-2 mt-5">
            <button
              className="bg-lightdark w-full py-2 rounded-md"
              onClick={handleReset}
            >
              Clear Filters
            </button>
          </div>
        </div>
        <div
          className={
            isSidebar
              ? `flex flex-col md:order-1 w-full`
              : `flex flex-col md:order-1 ${filterOpen ? 'w-full' : 'md:w-2/3'}`
          }
        >
          {filteredItems.length === 0 && (
            <div>No items match your filters. Please try adjusting.</div>
          )}
          {filteredItems &&
            filteredItems.map((fItems) => (
              <div key={fItems.name} className={'mb-4'}>
                <Accordion title={fItems.name} isOpen={true} className={``}>
                  <div
                    className={
                      isSidebar
                        ? `grid grid-cols-1 gap-4`
                        : `grid grid-cols-1 ${filterOpen ? 'md:grid-cols-4' : 'md:grid-cols-2'} gap-4`
                    }
                  >
                    {fItems.items.map((i) => (
                      <Fragment key={i.id}>
                        <div
                          className={
                            'flex flex-col gap-2 w-full rounded-md h-max-[320px]  bg-lightdark text-white'
                          }
                        >
                          <div className={`w-full h-[220px] rounded-md`}>
                            <img
                              className={`w-full h-full object-cover rounded-md`}
                              src={
                                i.image?.length > 0
                                  ? `${process.env.NEXT_PUBLIC_API_URL}/assets/images/${i.image[0].id}/${i.image[0].path_to_image}.jpg`
                                  : 'https://ironchopsticks.hopto.org/assets/dodo-placeholder.jpg'
                              }
                              alt={i.name}
                            />
                          </div>
                          <p className={` px-2 text-base mb-2`}>{i.name}</p>
                          <p className={` px-2 text-sm mb-8`}>
                            {i.description}
                          </p>
                          {i?.cost !== 0 && (
                            <p className={`mt-auto pb-4 px-2`}>${i.cost}</p>
                          )}
                        </div>
                      </Fragment>
                    ))}
                  </div>
                </Accordion>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Menu
