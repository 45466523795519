import Modal from '@/components/Modal'
import ReportForm from '@/components/Contact/ReportForm'

type ReportPopup = {
  setTrigger: (trigger: boolean) => void
  trigger: boolean
  itemId: number
}

const ReportPopup = (props: ReportPopup) => {
  const { setTrigger, trigger, itemId } = props

  return (
    <Modal state={trigger} setTrigger={setTrigger}>
      <>
        <div className={'text-black text-center text-lg font-bold'}>
          Report an Issue
        </div>
        <ReportForm itemID={itemId} />
      </>
    </Modal>
  )
}

export default ReportPopup
