// @ts-nocheck
import { ChangeEvent, FormEvent, useRef, useState } from 'react'
import axios from 'axios'
import posthog from 'posthog-js'
import { useReCaptcha } from 'next-recaptcha-v3'

type ReportPopup = {
  itemID: number
}

const ReportForm = (props: ReportPopup) => {
  const { itemID } = props
  const [msgSubmitted, setMsgSubmitted] = useState<boolean>(false)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    reply: false,
    contact_reason: 'Food Report',
    itemID: itemID,
  })
  const { executeRecaptcha } = useReCaptcha()

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormErrors((prevErrors) => prevErrors.filter((pe) => pe !== name))

    setFormData({ ...formData, [name]: value })
  }

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'
      textAreaRef.current.style.height =
        Math.max(textAreaRef.current.scrollHeight, 100) + 'px'
    }

    handleChange(e)
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const requiredFields = ['name', 'email', 'message']
    const errors: Record<string, string> = {}

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = field
      }
    })

    if (Object.keys(errors).length > 0) {
      setFormErrors(Object.keys(errors))
      return
    }
    const token = await executeRecaptcha('contact_submit')

    const res = await axios
      .post(`${process.env.NEXT_PUBLIC_API_URL}/contact`, {
        ...formData,
        phid: posthog.get_distinct_id(),
        contact_reason: 'contact form',
        token,
      })
      .catch((error) => {
        console.log('error')
        setFormErrors(['error'])
      })

    if (res?.data?.status === 'success') {
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
        reply: false,
        contact_reason: 'Food Report',
        itemID: itemID,
      })
      setMsgSubmitted(true)
    }
  }

  return msgSubmitted ? (
    <div className="center m-8 my-12 text-center text-xl font-bold">
      <h1 className="text-center text-2xl font-bold dark:text-black">
        Thank you for contacting us!
      </h1>
      <p className="text-center dark:text-black">
        {formData.reply
          ? 'We will get back to you soon.'
          : 'We will review your message swiftly.'}
      </p>
    </div>
  ) : (
    <div className="flex">
      <form onSubmit={handleSubmit} className="mx-2 mt-3 w-full">
        <div className="mb-2 ml-1">
          <label className="font-bold text-black">
            Name:
            <input
              type="text"
              name="name"
              className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6  border-gray-400
              ${formErrors.includes('name') && 'border-2 border-red-500'}`}
              value={formData.name}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="mb-2 ml-1">
          <label className="font-bold text-black">
            Email:
            <input
              type="email"
              name="email"
              className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6  border-gray-400
              ${formErrors.includes('email') && 'border-2 border-red-500'}`}
              value={formData.email}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="mb-2 ml-1">
          <label className="font-bold text-black">
            Message:
            <textarea
              ref={textAreaRef}
              className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 border-gray-400
              ${formErrors.includes('message') && 'border-2 border-red-500'}`}
              name="message"
              placeholder="Tell us your questions and feedback"
              value={formData.message}
              onChange={handleTextAreaChange}
            />
          </label>
        </div>
        {formErrors.includes('error') && <>Failed to save contact, try again</>}

        <button
          type="submit"
          className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Submit
        </button>
        <p className={`text-xs text-black mt-4`}>
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">
            Privacy Policy
          </a> and{' '}
          <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
          apply.
        </p>
      </form>
    </div>
  )
}

export default ReportForm
