import { useState } from 'react'
import { Image } from '@/types/FoodMerchant'
import PhotoViewer from '@/components/PhotoViewer'

type Props = {
  photos: Image[]
}

const MerchantImages = ({ photos }: Props) => {
  const [trigger, setTrigger] = useState(false)
  const [selected, setSelected] = useState<number>(0)

  return (
    <>
      {trigger && (
        <PhotoViewer
          trigger={trigger}
          images={photos}
          setTrigger={setTrigger}
          initialImage={selected}
        />
      )}
      <div className="block my-6">
        <div className="flex flex-row justify-between py-4">
          <p className="text-2xl">Photos:</p>
        </div>
        <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
          data-test-id="photo-grid"
        >
          {photos.map((p, index) => (
            <div key={p.id} data-test-id="merchant-images">
              <img
                className="h-[160px] rounded-md object-cover w-full"
                onClick={() => {
                  setTrigger((prev) => !prev)
                  setSelected(index)
                }}
                src={`${process.env.NEXT_PUBLIC_API_URL}/assets/images/${p.id}/${p.path_to_image}.jpg`}
                alt={`Image ${p.id}`}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default MerchantImages
