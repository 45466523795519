import { useFilterStore } from '@/hooks/useFilterStore'

type Props = {
  shouldSetSearch: boolean
  name: string
}

const Pill = ({ name, shouldSetSearch }: Props) => {
  const filters = useFilterStore((state) => state.filters)
  const addFilter = useFilterStore((state) => state.addFilter)
  const removeFilter = useFilterStore((state) => state.removeFilter)
  const setSearchState = useFilterStore((state) => state.setSearchState)
  const resetSelectedCard = useFilterStore((state) => state.resetSelectedCard)

  const getName = (name: string): string => {
    if (name === 'st patrick') return 'Irish'
    if (name === 'valentines') return 'Romantic'
    const words = name.split(' ')
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substring(1)
    }
    return words.join(' ')
  }

  const handleClick = () => {
    if (shouldSetSearch) {
      setSearchState(true)
      resetSelectedCard()
    }
    if (filters.includes(name)) {
      removeFilter(name)
    } else {
      addFilter(name)
    }
  }

  return (
    <button
      onClick={handleClick}
      className={`rounded-full border-[1px] border-black dark:border-white min-w-max px-3 py-1 mx-[2px] my-1 capitalize
            ${
              filters && filters.includes(name)
                ? 'bg-black text-white dark:bg-white dark:text-black'
                : 'text-black dark:text-white'
            }
            `}
    >
      {getName(name)}
    </button>
  )
}

export default Pill
