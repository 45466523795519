export interface Messages {
  ios: string
  android: string
  default?: string
}

export const getPlatformMessage = (messages: Messages): string => {
  const userAgent = navigator.userAgent
  const isIOS = /iPad|iPhone|iPod/.test(userAgent)
  const isAndroid = /Android/.test(userAgent)
  const defaultMsg = messages.default || ''

  if (isIOS) {
    return messages.ios || defaultMsg
  } else if (isAndroid) {
    return messages.android || defaultMsg
  }

  return messages.default || defaultMsg
}

export const checkPlatform = () => {
  const userAgent = navigator.userAgent
  const isIOS = /iPhone|iPod/.test(userAgent)
  const isiPad =
    navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0
  const isAndroid = /Android/.test(userAgent)
  const isApple = /Mac OS X/.test(userAgent)

  switch (true) {
    case isIOS:
      return 'isIOS'
    case isiPad:
      return 'isiPad'
    case isApple:
      return 'isApple'
    case isAndroid:
      return 'isAndroid'
    default:
      return 'isPc'
  }
}
