import Pill from './Pill'
import { Fragment, useMemo } from 'react'
import { useFilterStore } from '@/hooks/useFilterStore'

type Props = {
  shouldSetSearch?: boolean
  shouldReorder?: boolean
  filterType?: string
  customList?: string[]
}

const PillFiltersContainer = (props: Props) => {
  const { shouldSetSearch, customList, filterType, shouldReorder } = props
  const filters = useFilterStore((state) => state.filters)
  const restrictions = useFilterStore((state) => state.restrictions)
  const cuisine = useFilterStore((state) => state.cuisine)

  let pills = restrictions
  if (filterType && filterType !== 'restrictions') {
    if (filterType === 'cuisine') {
      pills = cuisine
    } else {
      pills = ['Error, invalid filter list']
    }
  }

  if (customList) {
    pills = customList
  }

  const pillList = useMemo(() => {
    if (!shouldReorder) {
      return pills
    }
    const matching: string[] = []
    const nonMatching: string[] = []

    pills.forEach((item) => {
      if (filters.includes(item)) {
        matching.push(item)
      } else {
        nonMatching.push(item)
      }
    })

    return [...filters.filter((f) => matching.includes(f)), ...nonMatching]
  }, [pills, filters, shouldReorder])

  return (
    <Fragment>
      {pillList.map((name) => (
        <Pill key={name} name={name} shouldSetSearch={!!shouldSetSearch} />
      ))}
    </Fragment>
  )
}

export default PillFiltersContainer
