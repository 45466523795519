import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import { ReactNode, useState } from 'react'

type Props = {
  title: string
  children: ReactNode
  isOpen: boolean
  boldTitle?: boolean
  className?: string
}
const Accordion = ({
  title,
  children,
  isOpen,
  className,
  boldTitle,
}: Props) => {
  return (
    <Disclosure defaultOpen={isOpen}>
      {({ open }) => (
        <div className={`${className}`}>
          <Disclosure.Button
            className={`py-2 flex flex-row w-full justify-between ${boldTitle ? 'font-bold' : ''}`}
          >
            {title}
            {open ? (
              <ChevronUpIcon className={`h-6 w-auto`} />
            ) : (
              <ChevronDownIcon className={`h-6 w-auto`} />
            )}
          </Disclosure.Button>
          <Disclosure.Panel className="text-black dark:text-white">
            {children}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}

export default Accordion
